import {
  faHeadSideBrain,
  faUsers,
  faQuestionSquare,
  faFileUser,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import { List, Map } from 'immutable';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { useField } from 'formik';

import { isPending } from '^/responseStates';
import Alert from '^/components/Alert';
import DashItem from '^/components/dashboard/admin/DashItem';
import FormError from '^/components/FormError';
import FormItem from '^/components/forms/FormItem';
import SelectOrganisation from '^/components/SelectOrganisation';
import { ACTIVITY_TYPE } from '^/models/activity';
import { PRODUCT_TYPES } from '^/components/productVersions/choices';
import { isProductOrganisationAvailable } from '^/models/product';
import { Uuid } from '^/reducers/api/types';
import { selectResponse } from '^/selectors/responses';
import { StoreState } from '^/store';
import { getSimpleOrganisation } from '^/actions/actions';
import Loading from '^/components/Loading';

interface OnSelectQueryParams {
  activity_type: string;
  exclude_product_type?: string;
  product_type?: string;
}

interface ExternalProps {
  selectedOrganisation: Map<string, any>;
  onSelect: (queryParams: OnSelectQueryParams) => void;
  hasSelectedGroup?: boolean;
  groupId?: Uuid;
}

interface StateProps {
  response: Map<string, any>;
  user: Map<string, any>;
  getOrgResponse: Map<string, any>;
}

interface DispatchProps {
  getOrg: typeof getSimpleOrganisation;
}

type Props = StateProps & DispatchProps & ExternalProps;

const DASH_ITEMS = [
  {
    queryParams: {
      exclude_product_type: PRODUCT_TYPES.CHOICES.SJT,
      activity_type: ACTIVITY_TYPE.PSYCHOMETRIC,
    },
    title: i18next.t<string>('Psychometrics'),
    icon: faHeadSideBrain,
  },
  {
    queryParams: {
      activity_type: ACTIVITY_TYPE.THREE_SIXTY,
    },
    title: i18next.t<string>('360s'),
    icon: faUsers,
  },
  {
    queryParams: {
      product_type: PRODUCT_TYPES.CHOICES.SJT,
      activity_type: ACTIVITY_TYPE.PSYCHOMETRIC,
    },
    title: i18next.t<string>('SJTs'),
    icon: faQuestionSquare,
  },
  {
    queryParams: {
      activity_type: ACTIVITY_TYPE.PROFILER,
    },
    title: i18next.t<string>('Success Profiles'),
    icon: faFileUser,
  },
];

export const AccountTypeSetup: React.FC<Props> = props => {
  const isActivityTypeAvailable = (
    activityType: string,
    productType: string | undefined
  ) => {
    const { user, selectedOrganisation } = props;
    return selectedOrganisation
      ?.get('productorganisation_set', List())
      .some((productOrganisation: Map<string, any>) => {
        if (productType === PRODUCT_TYPES.CHOICES.SJT) {
          return (
            productOrganisation.get('activity_type') === activityType &&
            productOrganisation.get('product_type') ===
              PRODUCT_TYPES.CHOICES.SJT &&
            isProductOrganisationAvailable(user, productOrganisation)
          );
        }
        return (
          productOrganisation.get('activity_type') === activityType &&
          productOrganisation.get('product_type') !==
            PRODUCT_TYPES.CHOICES.SJT &&
          isProductOrganisationAvailable(user, productOrganisation)
        );
      });
  };

  const {
    response,
    selectedOrganisation,
    hasSelectedGroup,
    groupId,
    getOrg,
  } = props;

  const [field, meta, helpers] = useField('organisationId');

  useEffect(() => {
    if (field.value) {
      getOrg(field.value);
    }
  }, [getOrg, field.value]);

  return (
    <div>
      <Alert>
        <Trans i18nKey="TRANS import users alert activity create">
          If you want to import users via a CSV to your account, please go to
          the
          <a href="#/page/users/imports" target="_blank">
            User imports page
          </a>
        </Trans>
      </Alert>

      <div className="form-block">
        <SelectOrganisation
          value={field.value}
          name="organisationId"
          meta={meta}
          onChange={helpers.setValue}
          label={i18next.t<string>('Select account')}
          disabled={hasSelectedGroup}
          filters={groupId && { groups: groupId }}
          selectedOrganisation={selectedOrganisation}
          collectionName="organisations-list-ids"
        >
          <FormError response={response} formKey="organisation" />
        </SelectOrganisation>

        {isPending(props.getOrgResponse) ? (
          <Loading />
        ) : (
          <div>
            <FormItem
              label={i18next.t<string>('Select assessment type')}
              formItemClassName="product-type-wrapper"
            >
              {DASH_ITEMS.map(({ queryParams, title, icon }, idx) => (
                <DashItem
                  key={idx}
                  onClick={props.onSelect.bind(this, queryParams)}
                  title={title}
                  icon={
                    <FontAwesomeIcon
                      icon={icon}
                      size="5x"
                      className="dash-icon dash-request"
                    />
                  }
                  disabled={
                    !isActivityTypeAvailable(
                      queryParams.activity_type,
                      queryParams.product_type
                    )
                  }
                />
              ))}
            </FormItem>
            <FormError response={response} formKey="type" />
          </div>
        )}
      </div>
    </div>
  );
};

export function mapStateToProps(state: StoreState): StateProps {
  return {
    user: state.userProfile,
    response: selectResponse(state, 'addToCollection'),
    getOrgResponse: selectResponse(state, 'getOrganisationDetailSimple'),
  };
}

export default connect(mapStateToProps, {
  getOrg: getSimpleOrganisation,
})(AccountTypeSetup);
