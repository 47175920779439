import { List, Map } from 'immutable';

export const QUESTIONS_PER_PAGE = 10;

function questionIsFreetext(question: Map<string, any>): boolean {
  return question.getIn(['question360', 'is_freetext'], false);
}

export function moveTextQuestionsToEnd(productVersion: Map<string, any>) {
  const questions = productVersion.get('questions', List());
  const standardQuestions = questions.filter(
    (question: Map<string, any>) => !questionIsFreetext(question)
  );
  const freetextQuestions = questions.filter(questionIsFreetext);

  return productVersion.set(
    'questions',
    standardQuestions.concat(freetextQuestions)
  );
}

// TODO: deconstruct
abstract class PaginationInformation {
  protected _pagesInfo: Map<string, any> = Map();
  protected _progress: Map<string, any> = Map();

  protected abstract buildPagesInfo(
    questionsWithAnswers: List<List<Map<string, any>>>,
    multipleChoiceQuestionsWithAnswers?: List<List<Map<string, any>>>
  ): Map<string, any>;

  get pageCount(): number {
    return this.pages.count();
  }

  get pages(): List<List<List<Map<string, any>>>> {
    if (this._pagesInfo.get('currentPage').count()) {
      return this._pagesInfo
        .get('pages')
        .push(this._pagesInfo.get('currentPage'));
    }
    return this._pagesInfo.get('pages');
  }

  get isViewingLastPage() {
    return this.currentPageIndex === this.pageCount - 1;
  }

  get isViewingFirstPage() {
    return this.currentPageIndex === 0;
  }

  get currentPage(): List<List<Map<string, any>>> {
    return this.pages.get(this.currentPageIndex, List());
  }

  get currentPageIndex(): number {
    return this._progress.get('currentPage', 0);
  }

  get startOfTextQuestions(): number {
    return this._pagesInfo.get('startOfTextQuestions');
  }

  get firstUnansweredPageIndex(): number {
    return this._pagesInfo.get('firstUnansweredPageIdx');
  }

  set progress(newProgress: Map<string, any>) {
    this._progress = newProgress;
  }

  set questionsWithAnswers(questionsWithAnswers: List<List<Map<string, any>>>) {
    this._pagesInfo = this.buildPagesInfo(questionsWithAnswers);
  }
}

export class PaginationInformation360 extends PaginationInformation {
  constructor(
    questionsWithAnswers: List<List<Map<string, any>>>,
    progress: Map<string, any>
  ) {
    super();
    this._pagesInfo = this.buildPagesInfo(questionsWithAnswers);
    this._progress = progress;
  }

  public buildPagesInfo(questionsWithAnswers: List<List<Map<string, any>>>) {
    const initial = Map({
      pages: List(),
      currentPage: List(),
      hasSeenTextQuestion: false,
      startOfTextQuestions: null,
      firstUnansweredPageIdx: null,
    });

    const output = questionsWithAnswers.reduce(
      (memo: Map<string, any>, questionAndAnswer) => {
        let newPage = false;
        let hasSeenTextQuestion = memo.get('hasSeenTextQuestion');
        let startOfTextQuestions = false;
        const pages = memo.get('pages');
        const currentPage = memo.get('currentPage', List());

        if (
          !hasSeenTextQuestion &&
          questionAndAnswer.getIn([0, 'question360', 'is_freetext'])
        ) {
          hasSeenTextQuestion = true;
          newPage = true;
          startOfTextQuestions = true;
        } else if (currentPage.count() === QUESTIONS_PER_PAGE) {
          newPage = true;
        }

        const onFirstUnansweredQuestion =
          memo.get('firstUnansweredPageIdx') === null &&
          !questionAndAnswer.get(1);
        const firstUnansweredPageIdx = onFirstUnansweredQuestion
          ? memo.get('pages', List()).count() + (newPage ? 1 : 0)
          : memo.get('firstUnansweredPageIdx');

        if (newPage) {
          const newPageList = List.of(questionAndAnswer) as List<
            List<Map<string, any>>
          >;
          const updatedPages = pages.push(currentPage);
          return memo
            .set('hasSeenTextQuestion', hasSeenTextQuestion)
            .set('pages', updatedPages)
            .set('currentPage', newPageList)
            .set(
              'startOfTextQuestions',
              startOfTextQuestions
                ? updatedPages.count()
                : memo.get('startOfTextQuestions')
            )
            .set('firstUnansweredPageIdx', firstUnansweredPageIdx);
        }
        return memo
          .set('hasSeenTextQuestion', hasSeenTextQuestion)
          .set('currentPage', currentPage.push(questionAndAnswer))
          .set('firstUnansweredPageIdx', firstUnansweredPageIdx);
      },
      initial
    );

    return output.update('firstUnansweredPageIdx', existingIndex => {
      return existingIndex === null
        ? output.get('pages').count()
        : existingIndex;
    });
  }
}

export class PaginationInformationResilience extends PaginationInformation {
  constructor(
    questionsWithAnswers: List<List<Map<string, any>>>,
    multipleChoiceQuestions: List<List<Map<string, any>>>,
    progress: Map<string, any>
  ) {
    super();
    this._pagesInfo = this.buildPagesInfo(
      questionsWithAnswers,
      multipleChoiceQuestions
    );
    this._progress = progress.set(
      'currentPage',
      this._pagesInfo.get('firstUnansweredPageIdx')
    );
  }

  public buildPagesInfo(
    questionsWithAnswers: List<List<Map<string, any>>>,
    multipleChoiceQuestionsWithAnswers: List<List<Map<string, any>>>
  ) {
    const initial = Map({
      pages: List(),
      currentPage: List(),
      firstUnansweredPageIdx: null,
      seenMultipleChoiceQuestions: false,
    });

    const standardQuestions = questionsWithAnswers.reduce(
      (memo: Map<string, any>, questionAndAnswer) => {
        let newPage = false;
        const pages = memo.get('pages');
        const currentPage = memo.get('currentPage');

        if (currentPage.count() === QUESTIONS_PER_PAGE) {
          newPage = true;
        }

        const onFirstUnansweredQuestion =
          memo.get('firstUnansweredPageIdx') === null &&
          !questionAndAnswer.get(1);
        const firstUnansweredPageIdx = onFirstUnansweredQuestion
          ? memo.get('pages').count() + (newPage ? 1 : 0)
          : memo.get('firstUnansweredPageIdx');

        if (newPage) {
          const newPageList = List.of(questionAndAnswer);
          const updatedPages = pages.push(currentPage);
          return memo
            .set('pages', updatedPages)
            .set('currentPage', newPageList)
            .set('firstUnansweredPageIdx', firstUnansweredPageIdx);
        }
        return memo
          .set('currentPage', currentPage.push(questionAndAnswer))
          .set('firstUnansweredPageIdx', firstUnansweredPageIdx);
      },
      initial
    );

    const wrappedMultipleChoiceQuestions = multipleChoiceQuestionsWithAnswers.reduce(
      (memo, questionAndAnswer) => {
        let newPage = false;
        const pages = memo.get('pages');
        const currentPage = memo.get('currentPage');
        const seenMultipleChoiceQuestions = memo.get(
          'seenMultipleChoiceQuestions'
        );

        if (
          (!seenMultipleChoiceQuestions ||
            currentPage.count() === QUESTIONS_PER_PAGE) &&
          currentPage.count() !== 0
        ) {
          newPage = true;
        }

        const question = questionAndAnswer.get(0);
        const questionWithFlag = question.set('isMultipleChoice', true);
        const questionAndAnswerWithFlag = questionAndAnswer.set(
          0,
          questionWithFlag
        );

        const onFirstUnansweredQuestion =
          memo.get('firstUnansweredPageIdx') === null &&
          !questionAndAnswer.get(1);
        const firstUnansweredPageIdx = onFirstUnansweredQuestion
          ? memo.get('pages').count() + (newPage ? 1 : 0)
          : memo.get('firstUnansweredPageIdx');

        if (newPage) {
          const newPageList = List.of(questionAndAnswerWithFlag);
          const updatedPages = pages.push(currentPage);
          return memo
            .set('pages', updatedPages)
            .set('currentPage', newPageList)
            .set('seenMultipleChoiceQuestions', true)
            .set('firstUnansweredPageIdx', firstUnansweredPageIdx);
        }
        return memo
          .set('currentPage', currentPage.push(questionAndAnswerWithFlag))
          .set('seenMultipleChoiceQuestions', true)
          .set('firstUnansweredPageIdx', firstUnansweredPageIdx);
      },
      standardQuestions
    );

    return wrappedMultipleChoiceQuestions.update(
      'firstUnansweredPageIdx',
      existingIndex => {
        return existingIndex === null
          ? wrappedMultipleChoiceQuestions.get('pages').count()
          : existingIndex;
      }
    );
  }
}

const selfOthersRegex = /\[([^|\n\r]*?)\|([^|\n\r]*?)\]/g;
const interpolateRegex = /\[([^\]]*)\]/g;
const emailRegex = /\S+[@]\S+[.]\S+(?:\b|.)/;

export function interpolate<T extends Record<string, string>>(
  values: T,
  line: string
) {
  return line.replace(
    interpolateRegex,
    (match, captureGroup1) => values[captureGroup1] || match
  );
}

export function expandSelfOthers(
  isSelfRating: boolean | undefined,
  line: string
) {
  return line.replace(selfOthersRegex, isSelfRating ? '$2' : '$1');
}

export function highlightEmails(text: string) {
  const isEmail = emailRegex;
  // Replace all email strings with the email in <angle brackets>, which Markdown converts to a link
  return text.replace(isEmail, '<$&>');
}

export function replaceProfileName(
  text: string,
  searchString: string,
  replacement?: string
): string {
  if (
    !text ||
    !searchString ||
    replacement === undefined ||
    replacement === null
  ) {
    return text;
  }
  const escapedSearch = searchString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regex = new RegExp(escapedSearch, 'g');
  return text.replace(regex, replacement);
}

export function interpolateProductVersionDetails(
  text: string,
  productVersion: Map<string, any>,
  respondent?: Map<string, any>,
  isSelfRating?: boolean
) {
  // Currently, only the productVersion length needs to be interpolated
  const length = productVersion.getIn(
    ['map_product_version', 'max_session_length_minutes'],
    30
  );

  return highlightEmails(
    interpolate(
      {
        RESPONDENT_NAME: (respondent && respondent.get('full_name')) || '',
        MAX_SESSION_LENGTH_MINUTES: String(length),
      },
      expandSelfOthers(isSelfRating, text)
    )
  );
}

export function getMAPTextByKey(productVersion: Map<string, any>, key: string) {
  return interpolateProductVersionDetails(
    productVersion.getIn(['map_product_version', key], ''),
    productVersion
  );
}

export function getQuestionIdFromIndex(
  imageMatchQuestionsWithAnswers: List<List<Map<string, any>>>,
  index: number
) {
  return imageMatchQuestionsWithAnswers.getIn([index, 0, 'id']);
}

const LIKERT_ANSWERS: Record<string, ReadonlyArray<string>> = {
  '7': [
    'Strongly disagree',
    'Disagree',
    'Somewhat disagree',
    'Neither agree nor disagree',
    'Somewhat agree',
    'Agree',
    'Strongly agree',
  ],
  '5': [
    'Strongly disagree',
    'Disagree',
    'Neither agree nor disagree',
    'Agree',
    'Strongly agree',
  ],
};

export type LikertBounds = Readonly<{
  likert_minimum: number;
  likert_maximum: number;
  headers: ReadonlyArray<string>;
}>;

export function getLikertBoundsInformation(
  productVersion: Map<string, any>,
  questionCollectionIdx: number | undefined = undefined
): LikertBounds {
  const productVersionLikertProduct =
    productVersion.getIn(['product', 'likert_product']) ||
    productVersion.getIn([
      'questioncollection_set',
      questionCollectionIdx,
      'likert_question_collection',
    ]);
  if (productVersionLikertProduct) {
    const likertProduct: Omit<LikertBounds, 'headers'> &
      Partial<
        Pick<LikertBounds, 'headers'>
      > = productVersionLikertProduct.toJS();
    const numberOfAnswers =
      likertProduct.likert_maximum - likertProduct.likert_minimum - 1;
    return {
      headers: LIKERT_ANSWERS[numberOfAnswers],
      ...likertProduct,
    };
  }
  return {
    likert_minimum: 1,
    likert_maximum: 7,
    headers: LIKERT_ANSWERS[7],
  };
}
